<template>
  <div class="stage-parent">
    <!-- COMPONENTS -->
    <TheStageComponent
      v-for="item in taskComponents"
      :key="item.componentData.path"
      :task-object="item"
    />

    <!-- QUEUED COMPONENT -->
    <TheStageComponent
      v-if="taskComponentQueued"
      :key="taskComponentQueued.id + '-queued'"
      :task-object="taskComponentQueued"
      @destroyed="onDestroyed()"
    />

    <!-- QUEUE DEBUG -->
    <!-- <div class="queue-test">
			<h5>QUEUE:</h5>
			<div v-for="item in queueTest" :key="item.id + 'quudwdw'">
				<b>{{ item.group }}</b
				>: {{ item.data.arg }}
			</div>
			<hr />
			<h5>CURRENT:</h5>
			<div v-if="queueTest.length">
				<b>{{ queueTest[0].group }}</b
				>: {{ queueTest[0].data.arg }}
			</div>
		</div> -->
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import TheStageComponent from "./TheStageComponent.vue";

  export default {
    components: {
      TheStageComponent,
    },
    props: {
      exclude: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        queueCooldownTimeout: null,
        queueCooldownTimeoutDuration: 2000,
        queueCooling: false,
      };
    },
    computed: {
      ...mapGetters({
        getActiveTask: "tasks/getActiveTask",
        // Get ALL tasks regardless of 'inactivity' or 'sleeping'.
        getAllTasks: "tasks/getAllTasks",
      }),

      //--------------------------------------------------------------------
      // ALERTS RUNNING
      // Alerts program is a core system feature.
      //--------------------------------------------------------------------

      alertsRunning() {
        return !!this.getActiveTask("alerts", "Run");
      },

      //--------------------------------------------------------------------
      // TASK COMPONENTS DEQUEUED
      // * https://stackoverflow.com/questions/50032146/vue-child-component-leave-transition-callback-not-working.
      // * https://stackoverflow.com/a/56768137/500182
      // * v-show hides to save CPU, it doesn't need to know about transitions.
      //--------------------------------------------------------------------

      taskComponents() {
        // Tasks *can* return a possible list of duplicates (inactive, sleeping etc).
        const tasks = this.getAllTasks.filter(
          (item) =>
            item.componentData.path &&
            !item.componentData.destroyed &&
            !item.componentData.queued &&
            !(this.exclude && item.componentData.zType == this.exclude)
        );

        // Return final unique array (remove dups by componentData.path);
        return [...new Map(tasks.map((item) => [item.componentData.path, item])).values()];
      },

      //--------------------------------------------------------------------
      // TASK COMPONENTS QUEUED
      // If queue not cooling, release next item in queue (reversed; old to new)
      //--------------------------------------------------------------------

      taskComponentQueued() {
        const queue = this.getAllTasks.filter(
          (item) =>
            item.componentData.path &&
            !item.componentData.destroyed &&
            item.componentData.queued &&
            !(this.exclude && item.componentData.zType == this.exclude)
        );

        return (
          !this.queueCooling &&
          this.alertsRunning && // this may present problems in future with non-alert components.
          queue.length &&
          queue[0]
        );
      },

      //--------------------------------------------------------------------
      // DEBUG
      //--------------------------------------------------------------------

      queueTest() {
        return this.getAllTasks.filter(
          (item) =>
            item.componentData.path && !item.componentData.destroyed && item.componentData.queued
        );
      },
    },

    //--------------------------------------------------------------------
    // METHODS: ON DESTROYED
    //--------------------------------------------------------------------

    methods: {
      onDestroyed() {
        this.queueCooling = true;

        // Wait out cooldown before releasing next item in queue.
        clearTimeout(this.queueCooldownTimeout);
        this.queueCooldownTimeout = setTimeout(
          () => (this.queueCooling = false),
          this.queueCooldownTimeoutDuration
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .stage-parent {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // border: 2px dashed aqua;
  }

  .queue-test {
    position: absolute;
    top: 0;
    right: 0;
    width: 400px;
    height: 100%;
    overflow: auto;
    padding: 10px;
    background: rgba(white, 0.4);
    z-index: 999;
  }
</style>
