<template>
  <div class="stage-component-parent">

    <!-- PRELOADER -->
    <Preloader
      v-if="preloading && assetsManifest"
      :manifest="assetsManifest"
      @preload-complete="onPreloadComplete"
      @total-progress-percentage="onUpdateProgress"
    />

    <!-- STAGE COMPONENT -->
    <div class="stage-component">

      <!-- DYNAMIC COMPONENT -->
      <component
        :is="component"
        v-if="component && !preloading"
        v-show="isActive"
        :options="taskObject.componentData && taskObject.componentData.options"
        :taskObject="taskObject"
      />

    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import Preloader from "@/components/Preloader.vue";
  // EG. cache import '@/components/MitchOS/Programs/Debug/Debug';

  export default {
    components: {
      Preloader,
    },
    props: {
      taskObject: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        assetsManifest: null,
        component: null,
        destroyTimeout: null,
        preloading: true,
      };
    },
    computed: {
      isActive() {
        const rc = this.taskObject.componentData.renderCondition;
        let renderCondition = [];

        if (rc) {
          rc.forEach((item) => {
            const getter = this.$store.getters[item.getter];

            // eg. this.$store.getters['youtube/getPlaylist'].length
            //     this.$store.getters['scenes/isLobby']
            if (!item.args?.length) {
              const result = getter.length || getter;
              renderCondition.push(item.bool ? result : !result);
            }

            // Eg. this.$store.getters['tasks/getActiveTask']('dashboardTVChannel', 'Off')
            if (item.args?.length) {
              const result = !!getter?.(item.args[0], item.args[1]);
              renderCondition.push(item.bool ? result : !result);
            }
          });
        }

        const passesCondition = !renderCondition.length || renderCondition.every((item) => item);

        // console.log('task:', this.taskObject.data?.value, passesCondition);

        // execute the render condition outlined in data/task..
        return passesCondition && !this.taskObject.canceled && !this.taskObject.sleeping;
      },
      //--------------------------------------------------------------------
      // ASYNC COMPONENTS
      // https://medium.com/scrumpy/dynamic-component-templates-with-vue-js-d9236ab183bb
      // https://vueschool.io/articles/vuejs-tutorials/async-vuejs-components/
      // TODO: show splash screen during load
      //--------------------------------------------------------------------

      loader() {
        if (!this.taskObject) {
          return null; // Trigger error.
        }
        return () => import(`@/components/MitchOS${this.taskObject.componentData.path}`);
      },

      //--------------------------------------------------------------------
      // DESTROY DELAY - Used for triggering parent transitions.
      // The parent must complete the transition before the component is
      // destroyed, otherwise the transition will cease in motion.
      //--------------------------------------------------------------------

      destroyDelayDuration() {
        return this.taskObject.componentData.destroyDelay;
      },
    },
    watch: {
      //--------------------------------------------------------------------
      // ALWAYS RUNNING...
      // Code in onMount/created only fires on first appearance despite being "always running".
      // It's always running after the FIRST TIME it runs.
      //
      // When component tasks are canceled, they "power down" before 'destroyed'.
      // This is to handle exit transitions gracefully (EG. alerts fading out).
      // Components 'alwaysRunning' skip the destroy, they're always ready to go,
      // they just display:none when canceled. (eg. lobby icons, to prevent skips).
      // High performance components are expensive to rerender on the fly.
      //--------------------------------------------------------------------

      isActive(newVal) {
        if (!newVal && !this.taskObject.componentData.alwaysRunning) {
          clearTimeout(this.destroyTimeout);
          this.destroyTimeout = setTimeout(() => {
            this.$store
              .dispatch("tasks/destroyComponentTasks", this.taskObject.componentData.path)
              .then(() => {
                // console.info(this.taskObject.data.value + ' was destroyed.');
              });
            // * Components are removed for CPU gains.
            // * DestroyDelayDuration coincides with parent transition time.
          }, this.destroyDelayDuration);
        }
      },
    },
    mounted() {
      this.loader()
        .then(() => {
          this.component = () => this.loader();

          // 1. Get assets folder of component (if exists).
          const path = "@/components/MitchOS" + this.taskObject.componentData.path;
          const pathArr = path.split("@/components")[1].split("/");
          pathArr.pop();
          const componentFolderPath = pathArr.join("/");
          const manifest = composeManifest([componentFolderPath]);

          // 2. If assets exist, pass manifest to preloader. Update the progress on the taskObject directly.
          manifest.length ? (this.assetsManifest = manifest) : this.onPreloadComplete();
        })
        .catch(() => console.warn("Oops! Component failed to load."));
    },
    beforeDestroy() {
      this.$emit("destroyed");
    },
    methods: {
      //--------------------------------------------------------------------
      // ON PRELOAD COMPLETE
      //--------------------------------------------------------------------

      onPreloadComplete() {
        // The component will now reveal.
        this.preloading = false;

        // Update progress bar.
        this.onUpdateProgress(100);

        // 1. Parallel tasks are now able to run.
        // 2. Stamp all tasks with the parentId as final arg (for use with debug).
        if (this.taskObject.tasksInParallel.length) {
          this.$store.dispatch("tasks/runTasks", this.taskObject.tasksInParallel, this.taskObject.id);
        }

        // 3. Run the dorment timing function after load (duration, startDelay etc)
        if (this.taskObject.timingFunction) {
          this.taskObject.timingFunction();
        }
      },
      //--------------------------------------------------------------------
      // ON UPDATE PROGRESS
      //--------------------------------------------------------------------

      onUpdateProgress(event) {
        this.taskObject.componentData.progressPerc = event || 0;
      },
    },
  };
</script>

<style lang="scss" scoped>
  //--------------------------------------------------------------------
  // WINDOW
  //--------------------------------------------------------------------

  .stage-component-parent {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    color: rgba(white, 0.4);
    // overflow: hidden; // need to review this
    box-shadow: 0 3px 14px 2px rgba(black, 0.2);
    pointer-events: auto;
    // border: 2px dashed yellow;
  }

  //--------------------------------------------------------------------
  // WINDOW BODY
  //--------------------------------------------------------------------

  .stage-component {
    display: flex;
    // position: relative; // is this a good move?
    // border: 1px dashed black;
    height: 100%;
    overflow: hidden;
    padding: rem-calc(30 10); // get global
  }
</style>